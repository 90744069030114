import capitalize from "lodash/capitalize";

import { Partner } from "#api.los/client.types";

/**
 * Gives back the partner and product we are currently in
 * This may only be needed at the very beginning of flows, while
 * still unauthenticated. After that point, we should be solidly
 * on a given partner+product pair, and this should come back
 * in the page initialization from the BE, which is an improvement
 * as this sort of string interperetation is brittle.
 * MPR, 2022/11/30: We should put some thought into how to generalize
 * this to include the dashboard as well
 * @param path the current url path
 */
export const getFlowSegments = (path: string) => {
  const segments = path.split("/");
  if (!segments[0]) {
    // disregard leading slash
    segments.shift();
  }
  if (!segments[segments.length - 1]) {
    // disregard trailing slash
    segments.pop();
  }
  if (segments.length > segments.filter((p) => !!p).length) {
    throw new Error("empty path segment. Some route has not been defined.");
  }
  const getNonPartnerSegment = (segment: string) => {
    switch (segment) {
      case "new-loan":
        return { product: "new-loan" };
      case "refi":
        return { product: "refi" };
      case "refinance":
        return { product: "refinance" };
      case "new-loan-turo":
        // legacy partner format
        return { product: "new-loan", partner: "turo" };
      case "dashboard":
        return { product: "dashboard" };
      case "examples":
        return { product: "examples" };
      case "claim":
        return { product: "claim" };
      case "apply":
        return { product: "default" };
      default:
        throw new Error("unable to parse url path. Unknown segment provided.");
    }
  };
  if (segments.length === 1) {
    // we are in the old form flow/new flow landing
    return getNonPartnerSegment(segments[0]);
  }
  if (segments.length === 2) {
    // we are either in partnerless app, or partnered old flow/new flow landing
    if (Partner[capitalize(segments[0])]) {
      // partnered old flow/new flow landing
      return {
        // MT, return partner string as lower case to prevent capitalizing in the url
        partner: segments[0].toLowerCase(),
        product: segments[1],
      };
    }
    // partnerless app
    return getNonPartnerSegment(segments[0]);
  }
  if (segments.length >= 3) {
    // partnered new nav flow
    return {
      // MT, return partner string as lower case to prevent capitalizing in the url
      partner: segments[0],
      product: segments[1],
    };
  }
  throw new Error("An unknown error occurred");
};
