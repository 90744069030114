/* eslint-disable camelcase */
/** ******************************************************************************
LOS HTTP Structures
 ******************************************************************************* */

import {
  HTTPResponse,
  PaymentDayOfMonth,
  PaymentMethod,
} from "#api.shared/client.types";
import {
  EmploymentType,
  HousingOwnershipType,
  LoanStatus,
  LoanUserDocumentStatus,
  MilitaryBranchType,
  RefinanceLoanOfferType,
  UsStatesFragment,
} from "#apollo/deprecatedTypesStillInUse";
import {
  HostingPlatform,
  LoanClassV2,
  LoanType,
  VehicleUsage,
} from "#components/partial/ApplyPage/ApplyPage.types";
import { DealershipInfo } from "#components/partial/DealershipForm/DealershipForm.types";
import { LoanPreference } from "#components/partial/OffersIntent/OffersIntent.types";
import {
  AddOnsOutputDto,
  ChargerDecision,
  ChargerInstallDecision,
  ChargerInstallEligibilityDetails,
  ChargerInstallOption,
  ChargerProduct,
  ChargerProvider,
  GapDecision,
  WarrantyDecision,
} from "#components/provider/AddonProvider/AddonTypes";
import { CREDIT_BANDS, TERM_LENGTHS } from "#constants/calculatorConstants";
import { ExperimentsConfig } from "#services/ExperimentService/experiments.types";
import { TC_FuelType, TC_Listings } from "#types/trueCarBrowseAPI.types";

import {
  ApplicationOutputDto,
  BusinessInfoResponseDTO,
  BusinessOwnershipResponseDTO,
  VehicleOutputDto,
} from "./losDto.types";

export interface TrackingPayload {
  event: string;
  anonymousId?: string;
  // this is typed as any on the BE, I think that's fine in this specific case since this is just any details we think should be added to any generic event ever -Aiden
  details?:
    | {
        [key: string]: string | number | boolean;
      }
    | unknown;
}

export interface DocumentImageData {
  key: string;
  contentType: string;
  contentLength: number;
  contentMD5?: string;
}

export interface LoanUserDocument {
  id: string;
  requirement: string;
  isRequired: boolean;
  isSatisfied: boolean;
  channel: string[];
  status: LoanUserDocumentStatus;
}

export enum FuelType {
  ELECTRIC = "ELECTRIC",
  HYBRID = "HYBRID",
  GAS = "GAS",
  UNKNOWN = "UNKNOWN",
}

export interface VProfileDto {
  id: string;
  year: number;
  make: string;
  model: string;
  trim: string;
  fuelType: FuelType;
  tenetValuationCents: number | null;
}

export enum OfferAction {
  Confirm = "Confirm",
  Select = "Select",
}

export enum Partner {
  Turo = "Turo",
  Getaround = "Getaround",
  Hyrecar = "Hyrecar",
}

export enum ApplicationType {
  purchase = "purchase",
  refi = "refi",
}

export enum ApiCreditType {
  soft = "soft",
  hard = "hard",
}

export type LOSAPIResponse<ExpectedRes> = Promise<{
  response: HTTPResponse<ExpectedRes> | null;
  error: HTTPResponse<ExpectedRes> | null;
}>;

// The discriminated union makes this more reasonable to use
export type LOSAPIResponsev2<ExpectedRes> = Promise<
  | {
      response: HTTPResponse<ExpectedRes>;
      error: null;
    }
  | {
      response: null;
      error: HTTPResponse<ExpectedRes>;
    }
>;
/** ******
 * Expected Responses
 *
 ******* */
export interface HTTPUpdateUserRes {
  data: {
    createdAt: Date;
    modifiedAt: Date;
    id: string;
    referralCode: string;
    email: string;
    firstName?: string;
    lastName?: string;
    phoneNumber?: string;
    dateOfBirth?: Date;
  };
}

export interface HTTPcreateApplicationRes {
  data: {
    __typename: "LoanApplication";
    createdAt: string;
    id: string;
    loanType: LoanType; // In the future should be replaced with applicationType - Jordon
    status: LoanStatus;
  };
}

export interface Address {
  id: string;
  city: string;
  line1: string;
  line2: string;
  postalCode: string;
  stateCode: string;
  state?: string;
  usStateId?: string;
  moveInDate?: Date;
  moveOutDate?: Date;
  userIndicatedCurrentAddress?: boolean;
}

export interface DriversLicense {
  id: string;
  driversLicenseNumber: string;
  expiryDate: Date;
  issueDate: Date;
  stateAbbreviation: string;
}

export interface IncomeVerification {
  applicantId: string;
  inputs: {
    applicantLine1: string;
    applicantCity: string;
    applicantState: string;
    applicantZipcode: string;
    phoneNumber: string | null;
    email: string;
    dateOfBirth: Date;
    statedIncomeCents: number;
    ficoScore: number;
    dti: number;
    pti: number;
    occupation: string;
    employer: string;
    monthsAtEmployer: number;
    hasSSN: boolean;
  };
  outputs: {
    estimatedIncomeCents: number;
    highestEstIncomeCents: number;
    lowestEstIncomeCents: number;
    incomeVerificationScore: number;
    estimatedPti: number;
    numberOfAlerts: number;
    alertMessages: string[];
    eligibleToWaiveStip: boolean;
    eligibilityToWaiveReason: string | null;
  };
  invalidReason: string | null;
}

export interface Applicant {
  id: string;
  address: Address | Record<string, never>;
  hardCreditConsent: boolean;
  housing: {
    housingOwnershipType: HousingOwnershipType;
    monthlyPaymentCents: number;
    monthsLived?: number;
  };

  income:
    | {
        employmentType: EmploymentType;
        grossAnnualCents: number;
        employer: string;
        occupation: string;
        otherGrossIncome: number;
        employmentDurationMonths: number;
        contactNumber: string;
        militaryBranch: MilitaryBranchType;
      }
    | Record<string, never>;
  softCreditConsent: boolean;
  statedNotActiveMilitaryDuty: boolean;
  userAddressId: string;
  housingOwnershipType: string;
  grossAnnualCents: number;
  hardPersonalCreditReportId?: string;
  docusignGenerated: boolean;
  loanPreference?: LoanPreference;
}

export interface HTTPAutoVerifyIncomeRes {
  data: IncomeVerification;
}

export interface HTTPApplicantRes {
  data: Applicant;
}

export type ResApplicant = LOSAPIResponse<HTTPApplicantRes>;

export interface SoftCheckPrevLoan {
  estimatedApr: number;
  hash: string;
  lienholderName: string;
  monthlyPaymentCents: number;
  remainingPrincipalCents: number;
  remainingTermLengthMonths: number;
  termLengthMonths: number;
  totalPrincipalCents: number;
}

export enum VehiclePurchaseSource {
  Dealer = "Dealer", // We need generic Dealer for historical records where we simply didn't have more granularity
  // FranchiseDealer = "FranchiseDealer", // TODO Implement distinction between Franchise and Independent? For now, Ops will distinguish during Funding Review
  // IndependentDealer = "IndependentDealer", // TODO Implement distinction between Franchise and Independent? For now, Ops will distinguish during Funding Review
  OEM = "OEM", // Purchase directly from the OEM (Tesla, Rivian, Polestar, etc.)
  Refinance = "Refinance",
  Unknown = "Unknown",
}

export interface Vehicle {
  id: string;
  ageStatus: string;
  make?: string;
  model?: string;
  year?: number;
  trim?: string;
  trimOptions?: string[];
  mileage: number;
  salePriceCents: number;
  vin: string;
  uvc: string;
  image: { link: string };
  tenetValuationCents: number;
  deliveryDate?: Date;
  purchaseSource: VehiclePurchaseSource;
}

export interface PreviousLoan {
  id: string;
  totalPrincipalCents: number;
  remainingPrincipalCents: number;
  monthlyPaymentCents: number;
  apr: number;
  termLengthMonths: number;
  remainingTermLengthMonths: number;
}
/**
 * This is used by the getApplication endpoint
 * (ApplicationProvider)
 */
export interface Application {
  id: string;
  loanProId: number | null;
  loanType: LoanType;
  applicationStatus: string | null;
  status: LoanStatus;
  desiredBasePrincipalCents: number;
  desiredTermLengthMonths: number | null;
  loanClass?: LoanClassV2;
  partner?: HostingPlatform;
  hardPersonalCreditReportId: string | null;
  softPersonalCreditReportId: string | null;
  electedGapInsurance?: boolean;
  createdAt: Date;
  usage: VehicleUsage;
  previousLoan?: PreviousLoan;
  addOns?: {
    gap: {
      set: boolean;
      elected: boolean;
    };
    warranty: {
      set: boolean;
      elected: boolean;
    };
  };
  vehicle?: Vehicle;
  offers: {
    availableOffers: Offer[];
    selectedOffer?: SelectedOffer;
    hasOffers: boolean;
    hasSelectedOffer: boolean;
  };
  flags: {
    hasConfiguredAutopay: boolean;
    hasSubmittedSsn?: boolean | null;
    hasOptedInForAutopay?: boolean | null;
    applicationTasksFinalized?: boolean;
    hasMadeWarrantySelection?: boolean;
    electedGapInsurance?: boolean;
    hasPassedPrequal?: boolean;
    hasSignedDocusign?: boolean;
  };
  hostingInformation: HostingInformation;
}

export type HostingInformation = {
  platform: HostingPlatform;
};

export enum LoanClass {
  Individual = "Individual",
  CommercialHybrid = "CommercialHybrid",
}

export interface HTTPLoanApplicationResponse {
  data: Application;
}

export interface HTTPLoanApplicationsResponse {
  data: ApplicationOutputDto[];
}

export interface HTTPDealershipInfoResponse {
  data: DealershipInfo | null;
}

export interface HTTPStatesWaitingListRes {
  data: null;
}

export interface HTTPSoftCreditRes {
  data: {
    id: string;
    userId: string;
    isManuallyPulledReport: boolean | null;
    adminNote: string | null;
    status: string;
    statusMessage: string | null;
    createdAt: Date;
    updatedAt: Date;
  };
}

export interface HTTPIvsRes {
  data: {
    id: string;
    userId: string;
    estimatedGrossAnnualIncomeCents: number | null;
    incomeVerificationType: "Plaid";
    plaidAccessToken: string;
    plaidIncomeVerificationCompletedAt: Date | null;
    plaidIncomeVerificationPaystubsData:
      | string
      | number
      | boolean
      | Record<string, unknown>
      | unknown[]
      | null;
    plaidIncomeVerificationTaxformsData:
      | string
      | number
      | boolean
      | Record<string, unknown>
      | unknown[]
      | null;
    plaidItemId: string;
    createdAt: Date;
    updatedAt: Date;
  };
}

export interface HTTPEndIvsRes {
  data: "good!";
}

export interface HTTPCreateVehicleRes {
  data: ApplicationOutputDto;
}

export interface HTTPUpdateVehicleRes {
  data: VehicleOutputDto;
  error?: string;
}

export interface HTTPWaitlistRes {
  data: {
    success: boolean;
  };
}

export interface HTTPModelsRes {
  data: VProfileDto[];
}

export interface HTTPMakesRes {
  data: {
    make: string;
  }[];
}

export interface HTTPModelsSearchRes {
  data: VProfileDto;
}

export interface HTTPModelsIsFinanceSupported {
  data: {
    isFinanceSupported: boolean;
  };
}
export interface HTTPDocusignRes {
  data: string;
}

export interface HTTPCancelApplicationRes {
  data: ApplicationOutputDto;
}

export interface HTTPCreateDirectRes {
  data: {
    apr: string;
    autopayApr: string;
    autopayBalloonPaymentCents: number;
    autopayMonthlyPaymentCents: number;
    autopayOption: {
      apr: string;
      balloonPaymentCents: number;
      createdAt: string;
      id: string;
      lifetimeDiscountCents: number | null;
      modifiedAt: string;
      monthlyDiscountCents: number | null;
      monthlyPaymentCents: number;
      offerAutopayId: string;
      offerStandardId: null;
      principalCents: number;
      termLengthMonths: number;
      totalInterestCents: number;
    };
    autopayPrincipalCents: number;
    autopayTermLengthMonths: number;
    autopayTotalInterestCents: number;
    balloonPaymentCents: number;
    canceledAt: null;
    createdAt: string;
    id: string;
    loanApplicationId: string;
    loanRepaymentType: string;
    monthlyPaymentCents: number;
    offerDataHash: string;
    paymentDayOfMonth: null;
    principalCents: number;
    selectedAt: null;
    standardOption: {
      apr: string;
      balloonPaymentCents: number;
      createdAt: string;
      id: string;
      lifetimeDiscountCents: number | null;
      modifiedAt: string;
      monthlyDiscountCents: number | null;
      monthlyPaymentCents: number;
      offerAutopayId: null;
      offerStandardId: string;
      principalCents: number;
      termLengthMonths: number;
      totalInterestCents: number;
    };
    termLengthMonths: number;
    totalInterestCents: number;
  };
}

export interface HTTPGetDocumentUploadLink {
  key: string;
  link: string;
  loanUserDocumentId: string;
}

export interface HTTPCreateTaskDocUploadLink {
  data: {
    link: string;
  };
}

export type OfferLimits = {
  deferredPaymentCents?: {
    min: number;
    max: number;
  };
  termLengthMonths?: {
    min: number;
    max: number;
  };
  loanPrincipalCents?: {
    min: number;
    max: number;
  };
};
export type BuildOffer = {
  offer: Offer | null;
  limits: OfferLimits | null;
  selections: {
    termLengthMonths?: number;
    deferredPaymentCents?: number;
  };
};

export enum OfferStatus {
  Active = "Active",
  Cancelled = "Cancelled",
}

export type LoanRepaymentType = {
  Installment: "Installment";
  Balloon: "Balloon";
};

export enum OfferLender {
  Tenet = "Tenet",
  Tresl = "Tresl",
}

export type PassthroughOffer = {
  apr: number;
  claimUrl: string;
  externalLosApplicationId: string;
  id: string;
  monthlyPaymentAmountCents: number;
  principalAmountCents: number;
  lender: Exclude<OfferLender, OfferLender.Tenet>;
  termLengthMonths: number;
};

export type Offer = {
  createdAt: Date;
  id: string;
  appId: string;
  status: OfferStatus;
  repaymentType: LoanRepaymentType;
  refiOfferType: RefinanceLoanOfferType;
  ltv: number;
  riskGrade: string;
  autopayTila: AutopayTila;
  tila: Tila;
  combinedAmountFinancedCents: number;
  terms: {
    termLengthMonths: number;
    deferredPaymentCents: number;
    purchasePriceCents: number;
  };
};

export type SelectedOffer = Offer & {
  selectedAt: Date;
};

export type AutopayTila = {
  principalCents: number;
  apr: number;
  termLengthMonths: number;
  deferredPaymentCents: number;
  monthlyPaymentCents: number;
  totalInterestCents: number;
  monthlyDiscountCents: number;
  lifetimeDiscountCents: number;
};

export type Tila = {
  principalCents: number;
  apr: number;
  termLengthMonths: number;
  deferredPaymentCents: number;
  monthlyPaymentCents: number;
  totalInterestCents: number;
};

export type OfferOrRejection = {
  offer: Offer | null;
  /** used for admin dashboard */
  rejectionReasons: string[] | null;
};
export type OfferOrRejectionSet = {
  termLength: number;
  standard: OfferOrRejection;
  deferred: OfferOrRejection;
  recommendedStandard: OfferOrRejection;
  recommendedDeferred: OfferOrRejection;
  lender: OfferLender.Tenet;
};

export type HTTPBuildOfferResponse = {
  data: BuildOffer;
};

export type HTTPGetAddonsBody = { data: AddOnsOutputDto };

export type HTTPGetChargerEligibilityResponse = {
  data: ChargerInstallEligibilityDetails;
};

export type HTTPPostWarrantyBody = { data: WarrantyDecision };

export type HTTPPostGAPBody = { data: GapDecision };

export type HTTPPutPaymentPreference = { data: Application };

export type HTTPGetDocumentsResponse = LoanUserDocument[];

export type HTTPCreateChargerEstimateRes = {
  data: ChargerInstallOption;
};

export type HTTPPostChargerInstallDecisionRes = {
  data: ChargerInstallDecision;
};

export type HTTPPostChargerDecisionRes = {
  data: ChargerDecision;
};

export interface ChargerInstallDecisionInputDto {
  elected: boolean;
}

export interface ChargerDecisionInputDto {
  elected: boolean;
}

export interface ChargerPricingInputDto {
  externalSku: string;
  provider: ChargerProvider;
}

export type HTTPChargerPricingRes = {
  data: ChargerPricing;
};

export type ChargerPricing = ChargerProduct; // This charger product WILL INCLUDE sales tax in total cost

export type HTTPConfirmOfferRes = {
  data: ApplicationOutputDto;
};

export enum ApplicationStatus {
  // The currently active application
  ACTIVE = "ACTIVE",
  // Docusign has been submitted, loan status is "Funding" or "ApplicationReceived"
  PROCESSING = "PROCESSING",
  // The application has been cancelled by the user
  // Starting a new application will cancel the currently
  // active application
  CANCELLED = "CANCELLED",
  // The application has expired
  EXPIRED = "EXPIRED",
  // The application has been approved
  APPROVED = "APPROVED",
  // The application has been rejected
  REJECTED = "REJECTED",
  // The application was cancelled by tenet
  CANCELLED_BY_ADMIN = "CANCELLED_BY_ADMIN",
  // The application was manually rejected by tenet
  REJECTED_BY_ADMIN = "REJECTED_BY_ADMIN",
}

export type HTTPSelectOfferRes = {
  data: Offer;
};

export type HTTPGetPrequalRes = {
  data?: {
    lender: OfferLender;
    maxApr: number;
    minApr: number;
  };
  message?: string;
};

export type HTTPGetOffersRes = {
  data: OfferOrRejectionSet[] | PassthroughOffer[];
};

export type HTTPGetLimitsRes = {
  data: OfferLimits;
};

export type HTTPGetOfferRes = {
  data: Offer;
};

export type HTTPEmailOfferRes = {
  data: {
    id: string;
  };
};

export type HTTPUSStatesResponse = UsStatesFragment[];

export type InitVehicle = {
  vin: string;
  year?: string;
  make?: string;
  model?: string;
  trim?: string;
};

export type InitApplication = {
  id: string;
  createdAt: string;
  loanType: LoanType;
  status: LoanStatus;
  applicationStatus: ApplicationStatus | null;
  readableName?: string;
  vehicle: InitVehicle;
};

export type InitLoan = {
  id: string;
  createdAt: string;
  externalLmsLoanId: string;
  loanType: LoanType;
  loanApplicationId?: string;
  vehicle: InitVehicle;
  flags?: {
    isDelinquent: boolean;
  };
  readableName?: string;
};

export type HTTPInitPageResponse = {
  loggedIn: boolean;
  applications?: InitApplication[];
  userId?: string;
  email?: string;
  firstName?: string;
  ercot?: boolean;
  loans?: InitLoan[];
  featureFlags?: { [key: string]: boolean };
  experiments?: { [experiment in keyof ExperimentsConfig]: string }; // experiment name -> variant (bucket) name
  identity?: string;
};

export type PossiblePreviousLoan = {
  termLengthMonths: number;
  monthlyPaymentCents: number;
  totalPrincipalCents: number;
  remainingPrincipalCents: number;
  hash: string;
  estimatedApr?: number;
  remainingTermLengthMonths?: number;
};

export type User = {
  id: string;
  dateOfBirth: string;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  referralCode: string;
  createdAt: string;
  modifiedAt: string;
  addresses: Address[];
  hasSoftCreditReport: boolean;
  driversLicense: DriversLicense;
  hasHardCreditReport: boolean;
  hasEmailVerified: boolean;
  hasSmsVerified: boolean;
  possiblePreviousLoans: PossiblePreviousLoan[];
  ssnLast4Digits: string;
};

export type HTTPUserGet = {
  data: User;
};
export enum EmailVerificationType {
  Join = "Join",
  Change = "Change",
}
export type EmailVerification = {
  id: string;
  userId: string;
  email: string;
  verificationType: EmailVerificationType;
  verifiedAt: Date | null;
};

export type HTTPUpdateUserAddressRes = {
  data: Address;
};

export type HTTPCreateUserAddressRes = {
  data: Address;
};

export type HTTPUpdateEmailRes = {
  data: EmailVerification;
};

export type HTTPVerifyWithCode = {
  data: unknown;
};

export type HTTPResendCode = {
  data: unknown;
};

export type HTTPDocusignResDEPRECATED = {
  data: ApplicationOutputDto;
};

export interface PrevLoanOutputDto {
  id: string;
  lienholderName?: string;
  apr: number;
  monthlyPaymentCents: number;
  remainingPrincipalCents: number;
  remainingTermLengthMonths: number;
  termLengthMonths: number;
  totalPrincipalCents: number;
}

export type HTTPUpsertPrevLoanRes = {
  data: PrevLoanOutputDto;
};

export type HTTPCreateAutopay = {
  data: string;
  overrideAmountCents?: number;
};

export interface ApplicationAutopayConfig {
  createdAt: Date;
  id: string;
  modifiedAt: Date;
  overrideAmountCents: number;
  overrideDay: PaymentDayOfMonth;
  paymentMethod: PaymentMethod;
  paymentMethodId: string;
}

export type HTTPGetAutopay = {
  data: ApplicationAutopayConfig;
};

export type HTTPCancelAutopay = {
  data: string;
};

// Features
export enum Feature {
  Insights = "Insights",
}

// Experiments
type ExperimentBucket = {
  experimentName: string;
  bucketName: string;
};

type ExperimentEvent = {
  experimentName: string;
  eventName: string;
};

export type HTTPResponseCreateExperimentBucket = LOSAPIResponsev2<{
  data: ExperimentBucket;
}>;

export type HTTPResponseCreateExperimentEvent = LOSAPIResponsev2<{
  data: ExperimentEvent;
}>;

export type extractBody<Type> = Type extends LOSAPIResponse<infer X>
  ? X
  : never;

export type ResponseLOSGetCalculator = LOSAPIResponse<{
  data: {
    [fico in CREDIT_BANDS]: {
      [term in TERM_LENGTHS]: number;
    };
  };
}>;

export interface HTTPGetBusinessRes {
  data: Record<
    string,
    {
      businessInfo: BusinessInfoResponseDTO;
      businessOwnership: BusinessOwnershipResponseDTO[];
    }
  >;
}

export interface HTTPUpsertBusinessRes {
  data: string;
}
export interface BudgetUserParams {
  year?: string;
  make?: string;
  model?: string;
  tcFuelType?: TC_FuelType;
  tcListingsType?: TC_Listings;
  creditBandLowerBound?: number;
  creditBandUpperBound?: number;
  desiredMonthlyDollars?: number;
  maxVehiclPriceDollars?: number;
  zipCode?: string;
  email?: string;
}
